import React from 'react';
import type { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import type { LinkProps as LinkPropsDesign } from '@marty-js/design/src/atoms/types';
import { BaseLink } from '@marty-js/design/src/atoms/link';
import { useSdkConfig } from '../utils/config';
import { useUtm } from '../utils/AnalyticsContext';

export type NavLinkProps = LinkPropsDesign &
  LinkProps & {
    as?: any;
    onClick?: React.MouseEventHandler<HTMLAnchorElement>;
    rawHref?: boolean;
    'data-testid'?: string;
  };

export function NavLink({
  children,
  className,
  as,
  title,
  onClick: onClickProp,
  rawHref,
  referer,
  'data-testid': dataTestId,
  href,
  rel,
  event,
  openInNewWindow,
}: React.PropsWithChildren<NavLinkProps>) {
  const { basePath } = useRouter();
  const sdkConfig = useSdkConfig();
  const utm = useUtm();

  let goodHref =
    (href || basePath) && !rawHref && typeof href === 'string' && !href.match(/^https?:/) ? basePath + href : href;

  const handleClick = (e: any) => {
    if (onClickProp) {
      onClickProp(e);
    }
  };

  if (referer) {
    // if (href && href.includes('clubi.cc')) {
    //   goodHref = `${href}?utm=${slugify(referer)}`;
    // }
  }

  if (href && href.includes('clubi.cc')) {
    goodHref = `${href}?${utm}`;
  }

  if (onClickProp && !href) {
    return (
      <BaseLink
        className={className}
        as={as}
        onClick={handleClick}
        title={title}
        data-testid={dataTestId}
        rel={rel}
        data-event={event?.type}
        target={openInNewWindow ? '_blank' : undefined}
      >
        {children}
      </BaseLink>
    );
  }

  if (goodHref?.match(/affilizz\.com/i)) {
    sdkConfig.hasAffilizzLink = true;
  }

  return goodHref ? (
    <BaseLink
      href={goodHref}
      className={className}
      as={as}
      onClick={handleClick}
      title={title}
      data-testid={dataTestId}
      rel={rel}
      data-event={event?.type}
      target={openInNewWindow ? '_blank' : undefined}
    >
      {children}
    </BaseLink>
  ) : null;
}
