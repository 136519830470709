import React, { useContext, createContext, PropsWithChildren, useEffect, useMemo } from 'react';
import { useTrackView } from '@marty-js/api-sdk/services/matomo';

import { formatDateWithTime } from '../atoms/date/utils';
import { slugify } from './utils';

declare global {
  interface Window {
    _paq: any;
    dataLayer: any;
  }
}

function randomBytesString(length: number) {
  const bytes = new Uint8Array(length);

  for (let i = 0; i < length; i += 1) {
    bytes[i] = Math.floor(Math.random() * 256);
  }

  return Array.from(bytes, (byte) => {
    // eslint-disable-next-line no-bitwise
    return `0${(byte & 0xff).toString(16)}`.slice(-2);
  }).join('');
}

export const defaultAnalyticsContext: AnalyticsContextType = {
  wordCount: 0,
  brandTag: null,
  contentType: null,
  url: null,
  title: null,
  urlReferer: null,
  userAgent: null,
  publishedAt: null,
  campaign: null,
  sponsoFlag: null,
  sectionsLabel: null,
  sections: null,
  breadcrumb: null,
  author: [],
  commentsCount: 0,
  tags: [],
};

type CustomDimension = {
  id: string;
  value: string;
};

type AnalyticsContextType = {
  wordCount?: number;
  brandTag?: string;
  contentType?: string;
  url?: string;
  title?: string;
  urlReferer?: string;
  userAgent?: string;
  publishedAt?: string;
  campaign?: string;
  sponsoFlag?: string;
  theme?: string;
  author?: string[];
  commentsCount?: number;
  tags?: string[];
  breadcrumb?: string;
  sections?: string;
  sectionsLabel?: string;
  type?: 'item' | 'section';
};

const AnalyticsContext = createContext<AnalyticsContextType>(defaultAnalyticsContext);

export const useAnalyticsContext = () => {
  const context = useContext(AnalyticsContext);

  return context;
};

export function useUtm() {
  const context = useContext(AnalyticsContext);

  let titleSlug = null;
  if (context.title) {
    titleSlug = slugify(context.title);
  }

  const params = new URLSearchParams();

  if (titleSlug) {
    params.append('t', titleSlug);
  }

  if (context.sections) {
    params.append('s', context.sections);
  }

  if (context.contentType) {
    params.append('c', context.contentType);
  }

  return params.toString();
}

export function useDatalayer({
  wordCount,
  contentType,
  title,
  url,
  publishedAt,
  brandTag,
  campaign,
  tags,
  author,
  breadcrumb,
  type,
  sections,
  sectionsLabel,
}: AnalyticsContextType) {
  const context = useContext(AnalyticsContext);
  if (type) {
    context.type = type;
  }
  if (contentType) {
    context.contentType = contentType;
  }
  if (title) {
    context.title = title;
  }
  if (author) {
    context.author = author;
  }
  if (wordCount) {
    context.wordCount = wordCount;
  }
  if (url) {
    context.url = url;
  }
  if (publishedAt) {
    context.publishedAt = publishedAt;
  }
  if (brandTag) {
    context.brandTag = brandTag;
  }
  if (campaign) {
    context.campaign = campaign;
  }
  if (tags) {
    context.tags = tags;
  }
  if (breadcrumb) {
    context.breadcrumb = breadcrumb;
  }
  if (sections) {
    context.sections = sections;
  }
  if (sectionsLabel) {
    context.sectionsLabel = sectionsLabel.replace(/&/g, 'et');
  }
}

export const Analytics: React.FC = (): null => {
  const context = useContext(AnalyticsContext);
  const [trackView] = useTrackView();

  useEffect(() => {
    context.userAgent = window.navigator.userAgent;
    context.urlReferer = document.referrer ?? '';
    context.theme =
      window.localStorage.getItem('preferMode') ??
      (window?.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');

    if (context?.tags.includes('sponsored')) {
      context.sponsoFlag = 'sponso';
    } else {
      context.sponsoFlag = 'N';
    }

    let dateFormated: string = null;
    let dateFormatedHour: string = null;

    if (context.publishedAt) {
      dateFormated = formatDateWithTime(context.publishedAt, 'dd/MM/yyyy');
      dateFormatedHour = formatDateWithTime(context.publishedAt, "HH'h'mm");
    }

    const customDimensions: CustomDimension[] = [
      { id: 'dimension1', value: context.contentType },
      { id: 'dimension2', value: context.author.join() },
      { id: 'dimension5', value: context.sponsoFlag },
      { id: 'dimension7', value: dateFormated ?? '' },
      { id: 'dimension8', value: dateFormatedHour ?? '' },
      { id: 'dimension9', value: context.commentsCount.toString() },
      { id: 'dimension10', value: context.campaign },
      { id: 'dimension11', value: context.wordCount.toString() },
      { id: 'dimension12', value: 'react' },
      { id: 'dimension14', value: context.theme },
    ];

    if (context.brandTag !== null) {
      customDimensions.push({ id: 'dimension4', value: context.brandTag });
    }

    if (context.breadcrumb !== null) {
      customDimensions.push({ id: 'dimension15', value: context.breadcrumb });
    }

    if (context.sections !== null) {
      customDimensions.push({ id: 'dimension16', value: context.sections });
    }

    if (context.sectionsLabel !== null) {
      customDimensions.push({ id: 'dimension17', value: context.sectionsLabel });
    }

    let token = window.localStorage.getItem('actionToken');

    if (!token) {
      token = randomBytesString(16);
      localStorage.setItem('actionToken', token);
    }

    if (context.title) {
      trackView({
        variables: {
          userToken: token,
          url: context.url,
          title: context.title,
          urlReferer: context.urlReferer,
          userAgent: context.userAgent,
          customDimensions,
          tags: context.tags,
        },
      });
    }
  }, [context, trackView]);

  return null;
};

export function AnalyticsProvider({ children }: PropsWithChildren<{}>) {
  const value = useMemo<AnalyticsContextType>(() => {
    const v = { ...defaultAnalyticsContext };
    v.author = [];
    v.tags = [];

    return v;
  }, []);

  return <AnalyticsContext.Provider value={value}>{children}</AnalyticsContext.Provider>;
}
