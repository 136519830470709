import getConfig from 'next/config';
import type { Config } from '../types';

const nextConfig = getConfig();

export const config: Config = {
  env: 'preprod',
  baseUrl: `https://${nextConfig?.publicRuntimeConfig?.baseUrl}-front-k8s.pp.clubic.com`,
  ssoUrl: 'https://connect.clubic.com/',
  apiURL: `https://${nextConfig?.publicRuntimeConfig?.baseUrl}-api-k8s.pp.clubic.com/graphql`,
  imageApiHostname: 'https://pic.clubic.com',
};
