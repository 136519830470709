import React, { FunctionComponent, useMemo, StrictMode } from 'react';
import { AppContext, AppProps } from 'next/app';
import { ApiProvider, apiSdkInit, SDKConfig, useApiSdk } from '@marty-js/api-sdk';
import { GlobalAdStyle } from '@marty-js/sdk/src/ads/ad';
import { DarkModeProvider } from '@marty-js/design/src/utils/theme-switcher';
import { GlobalNormalize } from '@marty-js/design/src/utils/global-normalize';
import { GlobalReset } from '@marty-js/design/src/utils/global-reset';
import { InjectRootStyles } from '@marty-js/design/src/utils/global-style';
import { NavLink } from '@marty-js/sdk/src/atoms/nav-link';
import { Image } from '@marty-js/sdk/src/atoms/image/image';
import { SdkConfig, SdkConfigProvider } from '@marty-js/sdk/src/utils/config';
import { ComponentProvider } from '@marty-js/design/src/utils/component';
import { SkinProvider } from '@marty-js/design/src/utils/skin';
import { TranslationProvider, useTranslation } from '@marty-js/design/src/utils/translation';
import { defaultImageContext, ImageContextType, ImageProvider } from '@marty-js/sdk/src/utils/imageContext';
import { ApolloState } from '@marty-js/api-sdk/types';
import { darkTheme, lightTheme } from '@marty-js/design/src/themes/site-clubic';
import { AdProvider } from '@marty-js/sdk/src/ads/AdContext';
import { MetaSeoProvider } from '@marty-js/sdk/src/utils/metaSeoContext';
import type { MetaSeoType } from '@marty-js/sdk/src/utils/metaSeoContext';
import { Analytics, AnalyticsProvider } from '@marty-js/sdk/src/utils/AnalyticsContext';
import { AuthProvider } from '@marty-js/sdk/src/utils/AuthContext';
import { defaultUserContext, UserContextType } from '@marty-js/sdk/src/utils/useIsConnected';
import { AffilizzScriptComponent } from '@marty-js/sdk/src/utils/AffilizzScriptComponent';
import { SkinProps } from '@marty-js/design/src/atoms/types';
import TrackLinks from '@marty-js/sdk/src/utils/TrackMatomo';
import { config } from '../config';
import { InjectFont } from '../modules/layout/inject-font';
import MetaSEO from '../modules/header/MetaSeo';
import { message } from '../config/message';
import WonderPush from '../modules/WonderPush';
import Marfeel from '../modules/Marfeel';

const apiConfig: SDKConfig = {
  siteName: config.sitename,
  apiURL: config.apiURL,
};
apiSdkInit(apiConfig);

type AppPropsWithLayout = { Component: { Layout: FunctionComponent } } & AppProps<ApolloState>;
const ApiProviderWrapper: FunctionComponent<{ apiSdkClient: any; children: React.ReactNode }> = ({
  apiSdkClient,
  children,
}) => {
  return <ApiProvider client={apiSdkClient}>{children}</ApiProvider>;
};

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  const apiSdkClient = useApiSdk(pageProps);

  const t = useTranslation();

  const sdkConfig: SdkConfig = useMemo(
    () => ({
      hostname: new URL(config.baseUrl).hostname,
      baseUrl: config.baseUrl,
      env: process.env.APP_ENV,
      displayAd: config.displayAd,
      sitename: config.sitename,
      imageApiHostname: config.imageApiHostname,
      ssoUrl: config.ssoUrl,
      feedback: config.feedback,
      version: config.version,
      t,
    }),
    [t],
  );

  const metaSeo: MetaSeoType = useMemo(
    () => ({
      title: t('_app.metaSeo.title'),
      url: t('_app.metaSeo.url'),
      imageUrl: t('_app.metaSeo.imageUrl'),
      description: t('_app.metaSeo.description'),
    }),
    [t],
  );

  const skin: SkinProps = useMemo(
    () => ({
      top: null,
      left: null,
      right: null,
      color: null,
    }),
    [],
  );

  const imageProviderValue = useMemo<ImageContextType>(() => {
    const v = { ...defaultImageContext };
    v.firstImages = [];

    return v;
  }, []);

  const authProviderValue = useMemo<UserContextType>(() => ({ ...defaultUserContext }), []);

  return (
    <TranslationProvider value={message}>
      <ImageProvider value={imageProviderValue}>
        <DarkModeProvider darkTheme={darkTheme} lightTheme={lightTheme}>
          <SkinProvider value={skin}>
            <ComponentProvider imageComponent={Image} linkComponent={NavLink}>
              <GlobalNormalize />
              <GlobalReset />
              <InjectFont />
              <InjectRootStyles />
              <GlobalAdStyle />
              <ApiProviderWrapper apiSdkClient={apiSdkClient}>
                <SdkConfigProvider config={sdkConfig}>
                  <AdProvider>
                    <MetaSeoProvider value={metaSeo}>
                      <AnalyticsProvider>
                        <StrictMode>
                          <AuthProvider value={authProviderValue}>
                            <Component {...pageProps} />
                          </AuthProvider>
                          <MetaSEO />
                          <WonderPush />
                        </StrictMode>
                        <Marfeel />
                        <Analytics />
                        <TrackLinks />
                      </AnalyticsProvider>
                      <AffilizzScriptComponent />
                    </MetaSeoProvider>
                  </AdProvider>
                </SdkConfigProvider>
              </ApiProviderWrapper>
            </ComponentProvider>
          </SkinProvider>
        </DarkModeProvider>
      </ImageProvider>
    </TranslationProvider>
  );
};

App.getInitialProps = ({ ctx }: AppContext) => {
  if (ctx.req && config.canaryHost === ctx.req.headers.host) {
    apiConfig.apiURL = config.apiCanaryURL;
  } else {
    apiConfig.apiURL = config.apiURL;
  }

  return { props: {} };
};

export default App;
