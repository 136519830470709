import React from 'react';
import Head from 'next/head';
import { useAnalyticsContext } from '@marty-js/sdk/src/utils/AnalyticsContext';

const Marfeel = () => {
  const { contentType, sectionsLabel } = useAnalyticsContext();

  if (
    (contentType === 'news' ||
      contentType === 'deals' ||
      contentType === 'live' ||
      contentType === 'live_event' ||
      contentType === 'tutorial' ||
      contentType === 'best-pick' ||
      contentType === 'comparatif' ||
      contentType === 'long-read' ||
      contentType === 'product-review') &&
    sectionsLabel
  ) {
    const sections = sectionsLabel.split('|');

    const dynamicSections = sections.map((section, index) => `cat${index + 1}:${section}`).join(';');

    return (
      <Head>
        <meta property="mrf:tags" content={`content-type:${contentType};${dynamicSections}`} />
        <script
          defer
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
             !function(){"use strict";function e(e){var t=!(arguments.length>1&&void 0!==arguments[1])||arguments[1],c=document.createElement("script");c.src=e,t?c.type="module":(c.async=!0,c.type="text/javascript",c.setAttribute("nomodule",""));var n=document.getElementsByTagName("script")[0];n.parentNode.insertBefore(c,n)}!function(t,c){!function(t,c,n){var a,o,r;n.accountId=c,null!==(a=t.marfeel)&&void 0!==a||(t.marfeel={}),null!==(o=(r=t.marfeel).cmd)&&void 0!==o||(r.cmd=[]),t.marfeel.config=n;var i="https://sdk.mrf.io/statics";e("".concat(i,"/marfeel-sdk.js?id=").concat(c),!0),e("".concat(i,"/marfeel-sdk.es5.js?id=").concat(c),!1)}(t,c,arguments.length>2&&void 0!==arguments[2]?arguments[2]:{})}(window,3521,{} /*config*/)}();
            `,
          }}
        />
      </Head>
    );
  }

  return null;
};

declare global {
  interface Window {
    marfeel: any;
  }
}

export default Marfeel;
